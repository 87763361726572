import React, { useEffect } from 'react'
import './Gallery.css'
import AwesomeSlider from 'react-awesome-slider'
const Gallery = ({ setUnderLine }) => {
  useEffect(() => {
    setUnderLine('gallery')

  }, []);



  return (
    <>
      <div className="GalleryHeader">
        <h1>Gallery</h1>
        <div className="scrollLine">
          <div>
            <div></div>
          </div>
        </div>
      </div>

      <div className="universityCarousal slideGallary">
        <AwesomeSlider>
          <div className="videoBox">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/bMh3jVLXpCg" title="Grand Course Launch Event | BPP University | Sanwariya Education Consultants" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          {/* <div className="videoBox">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Vj12LeNoV5E" title="Oxford International BPP University | Sanwariya Education Consultants" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div> */}
          <div className="videoBox">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/IUKtQVFHJrc" title="BPP University Event | Sanwariya Education Consultants" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          {/* <div className="videoBox">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/L8wsuQA4I9w" title="Oxford International Manchester | BPP |  Sanwariya Education Consultants" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div> */}

          {/* <div className="videoBox">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/xBrv555-63A"
              title="BPP University | Saanwariya Educational Consultants"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div> */}


        </AwesomeSlider>
      </div>


      {/* tamamna photos  */}


      <div className="galleryContainer">


        <div className="frame">

          <div>
            <img src={'https://res.cloudinary.com/dwevw3pnz/image/upload/v1698297629/sanwariyaVideos/drive-download-20231026T045315Z-001/BPP_GROUP_PIC_n633ht.jpg'} alt="" />
          </div>
          <div>
            <img src={'https://res.cloudinary.com/dwevw3pnz/image/upload/v1698297637/sanwariyaVideos/drive-download-20231026T045315Z-001/mercy1_yi7ls4.jpg'} alt="" />
          </div>
          <div>
            <img src={'https://res.cloudinary.com/dwevw3pnz/image/upload/v1698297637/sanwariyaVideos/drive-download-20231026T045315Z-001/mercy_tvt0rr.jpg'} alt="" />
          </div>
        </div>

        <div className="frame">

          <div>
            <img src={'https://res.cloudinary.com/dwevw3pnz/image/upload/v1698297635/sanwariyaVideos/drive-download-20231026T045315Z-001/kochi_ROEHAMPTON_dcat1o.jpg'} alt="" />
          </div>
          <div>
            <img src={'https://res.cloudinary.com/dwevw3pnz/image/upload/v1698297633/sanwariyaVideos/drive-download-20231026T045315Z-001/ROEHAMPTON_2_atghow.jpg'} alt="" />
          </div>
          <div>
            <img src={'https://res.cloudinary.com/dwevw3pnz/image/upload/v1698297630/sanwariyaVideos/drive-download-20231026T045315Z-001/ROEHAMPTON_4_z5svj8.jpg'} alt="" />
          </div>
        </div>

        <div className="frame">

          <div>
            <img src={'https://res.cloudinary.com/dwevw3pnz/image/upload/v1698297630/sanwariyaVideos/drive-download-20231026T045315Z-001/MERCY_GROUP_m270js.jpg'} alt="" />
          </div>
          <div>
            <img src={'https://res.cloudinary.com/dwevw3pnz/image/upload/v1701108292/sanwariyaVideos/cgh2auycdm26kouwipjv.jpg'} alt="" />
          </div>
          <div>
            <img src={'https://res.cloudinary.com/dwevw3pnz/image/upload/v1698297628/sanwariyaVideos/drive-download-20231026T045315Z-001/MERCY_bo4ofg.jpg'} alt="" />
          </div>
        </div>
      </div>

      {/* tamamna photos end */}


      <div className="galleryContainer">


        <div className="frame">

          <div>
            <img src={require("../../images/gallery/g4.avif")} alt="" />
          </div>
          <div>
            <img src={'https://res.cloudinary.com/dwevw3pnz/image/upload/v1701108284/sanwariyaVideos/zlfqeoaee8hsdatctol5.jpg'} alt="" />
          </div>
          <div>
            <img src={'https://res.cloudinary.com/dwevw3pnz/image/upload/v1701108284/sanwariyaVideos/bqdveysuxng6phbvcvgj.jpg'} alt="" />
          </div>
        </div>

        <div className="frame">

          <div>
            <img src={require("../../images/gallery/g10.jpeg")} alt="" />
          </div>
          <div>
            <img src={require("../../images/gallery/g5.jpeg")} alt="" />
          </div>
          <div>
            <img src={require("../../images/gallery/g6.jpeg")} alt="" />
          </div>
        </div>

        <div className="frame">

          <div>
            <img src={require("../../images/gallery/g7.jpeg")} alt="" />
          </div>
          <div>
            <img src={require("../../images/gallery/g8.jpeg")} alt="" />
          </div>
          <div>
            <img src={require("../../images/gallery/g11.jpeg")} alt="" />
          </div>
        </div>
      </div>


      <div className="galleryContainer">

        <div className="frame">

          <div>
            <img src={require("../../images/gallery/o-1.jpg")} alt="" />
          </div>
          <div>
            <img src={require("../../images/gallery/o-2.jpg")} alt="" />
          </div>
          <div>
            <img src={require("../../images/gallery/o-3.jpg")} alt="" />
          </div>
        </div>

        <div className="frame">
          <div>
            <img src={require("../../images/gallery/s-1.jpg")} alt="" />
          </div>

          <div>
            <img src={require("../../images/gallery/o-4.jpg")} alt="" />
          </div>
          <div>
            <img src={require("../../images/gallery/s-2.jpg")} alt="" />
          </div>
        </div>

        <div className="frame">

          <div>
            <img src={require("../../images/gallery/s-3.jpg")} alt="" />
          </div>
          <div>
            <img src={require("../../images/gallery/s-4.jpg")} alt="" />
          </div>
          <div>
            <img src={require("../../images/gallery/s-5.jpg")} alt="" />
          </div>
        </div>
        <div className="frame">

          <div>
            <img src={require("../../images/gallery/s-6.jpg")} alt="" />
          </div>
          <div>
            <img src={require("../../images/gallery/s-7.jpg")} alt="" />
          </div>
          <div>
            <img src={require("../../images/gallery/s-8.jpg")} alt="" />
          </div>
        </div>
        <div className="frame">

          <div>
            <img src={require("../../images/gallery/s-9.jpg")} alt="" />
          </div>
          <div>
            <img src={require("../../images/gallery/s-10.jpg")} alt="" />
          </div>
          <div>
            <img src={require("../../images/gallery/s-1.jpg")} alt="" />
          </div>
        </div>
        <div className="frame">
          <div>
            {/* <img src={require("../../images/gallery/s-1.jpg")} alt="" /> */}
          </div>
          <div>
            <img src={require("../../images/radio city .jpg")} alt="" />
          </div>
        </div>


      </div>
    </>
  )
}

export default Gallery