import React from 'react'
import PageHeader from '../../components/pageHeader/pageHeader'
import './EventPage.css'
import EventUI from '../../components/EventUI/EventUI'

const EventPage = ({setUnderLine}) => {
  setUnderLine('event')
  return (
    <div>
            {/* <PageHeader heading={"Events"} path={"Events"} /> */}
            <EventUI/>

    </div>
  )
}

export default EventPage